@import "~@ng-select/ng-select/themes/default.theme.css";
@import '~ngx-print-element/styles.css';

html {
  min-height: 100%;
  position: relative;
}

body {
  margin: 0;
  /* margin-bottom: 60px; */
  background-color: #bdbdbd;
}

footer {
  background-color: black;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 50px;
  color: white;
}

.text-color-blue {
  color: #0086c9;
}

.background-color-blue {
  background-color: #000000;
}

.text-color-yellow {
  color: #000000;
}

.background-color-yellow {
  background-color: #000000;
}

.pointer-tooltip {
  cursor: pointer;
  color: #000;
}

.pointer-tooltip:hover {
  color: #ff6600;
}

.pointer {
  cursor: pointer;
  background-color: #ff6600;
  color: #fff;
}

.pointer:hover {
  background-color: #ac4703;
  color: #fff;
}

.pointer-listar {
  cursor: pointer;
  background-color: #000000;
  color: #fff;
}

.pointer-listar:hover {
  background-color: #000000;
  color: #fff;
}

.cursor-pointer {
  cursor: pointer;
}

.dropdown-item {
  color: #fff !important;
}

.dropdown-item:hover {
  color: #000 !important;
}

.card {
  border: 0;
  box-shadow: 30px 30px 30px 30px rgba(82, 63, 105, 0.1);
  -webkit-box-shadow: 30px 30px 30px 30px rgba(82, 63, 105, 0.1);
  -moz-box-shadow: 30px 30px 30px 30px rgba(82, 63, 105, 0.1);
  -ms-box-shadow: 30px 30px 30px 30px rgba(82, 63, 105, 0.1);
}

.modal-header {
  background-color: #ff6600 !important;
}

.card-header {
  background-color: #ff6600;
}

.btn-volver {
  border: none !important;
  background-color: transparent;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
}

.btn-volver:hover {
  color: #ac4703 !important;
}

.btn-danger {
  border: 0;
  border-radius: 15px;
  font-weight: bold;
}

.btn-primary,
.btn-primary:active,
.btn-primary:visited,
.btn-primary:focus,
.btn-primary:disabled {
  background-color: #ff6600;
  border-color: #ac4703;
  border-radius: 15px;
  border: 0 !important;
}

.btn-primary:hover {
  background-color: #ac4703 !important;
  border-color: #ff6600;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.075) inset, 0px 0px 4px #ff6600;
  border-radius: 15px;
}

.btn-success {
  background: #2ECC71;
  border: 0;
  border-radius: 15px;
  font-weight: bold;
}

.btn-success:focus {
  border-color: #52BE80;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.075) inset, 0px 0px 8px #0a043c;
  border-radius: 15px;
}

.btn-secondary {
  background: #1F4893;
  border: 0;
  border-radius: 15px;
  font-weight: bold;
}

.btn-secondary:focus {
  border-color: #1F4893;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.075) inset, 0px 0px 8px #002a79;
  border-radius: 15px;
}

.btn-secondary:hover {
  background-color: #002a79;
  border-color: #002a79;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.075) inset, 0px 0px 8px #1F4893;

}

.form-control:focus {
  border-color: #1F4893;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.075) inset, 0px 0px 8px #5499C7;
}

.bg-primary {
  background-color: #1e8be4 !important;
  border-color: #1e8be4 !important;
}

label {
  font-weight: bold;
}

.thead-naranja {
  background-color: #204c9e;
  color: white;
  font-weight: bold;
}

.big-modal .modal-dialog {
  max-width: 97%;
  width: 97%;
}

.cp-modal-header {
  background-color: #1D3730;
  color: #fff;
}

.btn-modal {
  border: none;
  outline: none;
  background-color: transparent;
  color: #fff;
  transform: scale(1);
  transition: all 250ms ease-in;

}

.btn-modal:hover {
  color: #fff;
  transform: scale(1.1);
  /* scaleX, scaleY */
}


.size-rut {
  width: 8rem;
  overflow: auto;
}

.size-nombre {
  width: 12rem;
  overflow: auto;
}

.size-fecha {
  width: 10rem;
  overflow: auto;
}

.size-direccion {
  width: 12rem;
  overflow: auto;
}

.size-mandante {
  width: 15rem;
  overflow: auto;
}

.tooltipCustom {
  position: relative;
  /* display: inline-block; */
}

.tooltipCustom .tiptext {
  visibility: hidden;
  width: 250px;
  background-color: #0086c9;
  color: #ffffff;
  text-align: center;
  border-radius: 15px 15px 15px 0px;
  padding: 5px 10px;
  position: absolute;
  z-index: 999;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.tooltipCustom:hover .tiptext {
  visibility: visible;
}

.tooltipCustom.top .tiptext {
  margin-left: 0;
  bottom: 110%;
}

.tooltipCustom.top .tiptext::after {
  margin-left: -5px;
  top: 100%;
  left: 50%;
  border-color: #2e2e2e transparent transparent transparent;
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tooltip .tiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 3px;
  padding: 6px 0;
  position: absolute;
  z-index: 1;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.tooltip .tiptext::after {
  content: "";
  position: absolute;
  border-width: 5px;
  border-style: solid;
}

.tooltip:hover .tiptext {
  visibility: visible;
}

.icono {
  width: 30px;
  width: 20px;

}

.icono-titulo {
  width: 50px;
  width: 50px;

}

.ngx-pagination {
  padding-left: 0px !important;
}

.ngx-pagination .current,
.btn-info {
  background: #204c9e !important;
  border: transparent !important;
  border-radius: 20px !important;
}

.ngx-pagination .current:hover {
  background: #204c9e !important;
  border-radius: 20px !important;
  border: transparent !important;
}
